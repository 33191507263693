import React from 'react';
import PropTypes from 'prop-types';

export const Install = ({ name, pkg, scope}) => {
  return (
    <>
      <div className="gatsby-highlight" data-language="bash">
        <pre className="language-bash">
          $ npm i @{scope}/{name}@{pkg.version}
        </pre>
      </div>
      <hr className="if" />
    </>
  );
};

Install.propTypes = {
  name: PropTypes.string,
  scope: PropTypes.string,
  pkg: PropTypes.shape({
    version: PropTypes.string
  })
};
