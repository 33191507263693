import React from 'react';
import PropTypes from 'prop-types';

import { ParagraphLead } from '../../components/Layout';

import ComponentDisclaimer from './ComponentDisclaimer';
import { Links as PackageLinks } from '../../components/Package';

export const PreFirstSection = ({ show, pkg, componentName }) => {
  if (!show) return null;

  return (
    <>
      <div className="ids-doc if text layout column left">
        <div className="ids-doc if text body">
          <ParagraphLead>{pkg.description}</ParagraphLead>
        </div>
        <div className="ids-doc if text body"></div>
      </div>
      <div className="ids-doc if text layout columns">
        <div className="ids-doc if text body">
          <ComponentDisclaimer />
        </div>
        <div className="ids-doc if text body">
          <PackageLinks pkg={pkg} scope="ids-core" componentName={componentName} />
        </div>
      </div>
    </>
  );
};

PreFirstSection.propTypes = {
  show: PropTypes.bool.isRequired,
  pkg: PropTypes.shape({ repository: PropTypes.shape({ url: PropTypes.string }), description: PropTypes.string })
    .isRequired,
  componentName: PropTypes.string
};
