import React from 'react';
import PropTypes from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import cx from 'classnames';

import { generateID } from '../../lib/helpers';
import { getComponentPackage } from '../../lib/helpers';

import EditLink from '../../components/editLink';
import { Install as PackageInstall } from '../../components/Package';
import { LinkBackToGuidelines } from '../../components/Component';
import {
  Title,
  NoDocumentationBlock,
  DocumentationContainer,
  Section,
  MDXBlock,
  TableOfContentsMobile
} from '../../components/Layout';

import { PreFirstSection } from './PreFirstSection';

export const SectionContainer = ({ edges, fullPage = false, edge, pkg }) => {
  const { node } = edge;
  const { fields = {}, fileAbsolutePath, frontmatter, html, body } = node;
  const { collection = '' } = fields;
  const { order, componentName, customPath, title, branch } = frontmatter;
  const _is_changelog = fileAbsolutePath && fileAbsolutePath.indexOf('CHANGELOG.md') !== -1;

  let _custom_path = customPath;
  let _title = title;
  let scope;
  let type;

  if (_is_changelog) {
    _custom_path = fields.customPath;
    _title = 'Changelog';
  }

  if (!_custom_path || (_custom_path && _custom_path === '')) return null;

  switch(branch) {
    case 'relax':
      scope = 'ids-relax-core';
      type = 'rlx';
      break;
    case 'ids-core':
    default:
      scope = 'ids-core';
      type = 'css';
      break;
  }

  const _documentation_body = <div className="if" dangerouslySetInnerHTML={{ __html: html }} />;
  const _id = generateID(_custom_path, _title);
  const _component_package = getComponentPackage(componentName, pkg, type);
  const _is_first_section_of_component = !!componentName && _component_package && order === 1;
  const _is_css_only_package = [
    '/components/layout/breakpoint/css',
    '/components/text/typography/css',
    '/components/utils/css',
    '/components/color/css',
    '/components/media/icons/css'
  ].includes(_custom_path);
  const _is_missing_documentation = !body && html.length === 0 && !_is_first_section_of_component;
  const _section_class_names = cx('ids-doc if section', {
    light: !_is_changelog,
    'is-changelog': _is_changelog
  });

  if (_is_missing_documentation) {
    return (
      <NoDocumentationBlock
        className={_section_class_names}
        collection={collection}
        frontmatter={frontmatter}
        fields={fields}
        isCSSPackage={true}
        isJSPackage={false}
        isWCPackage={false}
        componentPackage={_component_package}
        path={fileAbsolutePath}
      />
    );
  } else if (body) {
    return (
      <MDXBlock className={_section_class_names} id={_id}>
        <LinkBackToGuidelines
          title={title}
          show={!!_custom_path && _is_first_section_of_component && !_is_css_only_package}
          to={_custom_path ? _custom_path.replace('/css', '') : '/'}
        />
        <Title
          collection={collection}
          frontmatter={frontmatter}
          fields={fields}
          scope={scope}
          isCSSPackage={true}
          pkg={_component_package}
          fileAbsolutePath={fileAbsolutePath}
        />
        <PreFirstSection show={_is_first_section_of_component} pkg={_component_package} componentName={componentName} />
        {order === 1 && <PackageInstall scope={scope} name={componentName} pkg={_component_package}/>}
        <MDXRenderer>{body}</MDXRenderer>
        {order === 1 && <TableOfContentsMobile edges={edges} />}
        <EditLink path={fileAbsolutePath} />
      </MDXBlock>
    );
  }

  /* @todo Remove this when a "page" template is ready to be used, */
  if (fullPage && order === 1) {
    return (
      <section className="if hero">
        <div className="if container">
          <div className="if content">
            <Title
              collection={collection}
              frontmatter={frontmatter}
              fields={fields}
              fullPage={fullPage}
              pkg={_component_package}
              fileAbsolutePath={fileAbsolutePath}
            />
            {_documentation_body}
          </div>
          <div className="if image studio" style={{ backgroundImage: 'url(/images/creative.png)' }}></div>
        </div>
      </section>
    );
  }

  return (
    <Section className={_section_class_names} id={_id}>
      <DocumentationContainer>
        <LinkBackToGuidelines
          title={title}
          show={!!_custom_path && _is_first_section_of_component && !_is_css_only_package}
          to={_custom_path ? _custom_path.replace('/css', '') : '/'}
        />
        <Title
          collection={collection}
          frontmatter={frontmatter}
          fields={fields}
          scope={scope}
          isCSSPackage={true}
          pkg={_component_package}
          fileAbsolutePath={fileAbsolutePath}
        />
        <PreFirstSection show={_is_first_section_of_component} pkg={_component_package} componentName={componentName} />
        {order === 1 && <PackageInstall scope={scope} name={componentName} pkg={_component_package} />}
        {_documentation_body}
        {order === 1 && <TableOfContentsMobile edges={edges} />}
        <EditLink path={fileAbsolutePath} />
      </DocumentationContainer>
    </Section>
  );
};

SectionContainer.propTypes = {
  fullPage: PropTypes.bool,
  wide: PropTypes.bool,
  edges: PropTypes.arrayOf(PropTypes.shape({})),
  edge: PropTypes.shape(),
  pkg: PropTypes.shape({})
};
