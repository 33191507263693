import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Page } from '../docs/CSS';

import idsCSSPackage from '@ids-core/bundle/package.json';

const CSSPageTemplate = ({ data }) => {
  const _sorted_edges = [...data.allMarkdownRemark.edges, ...data.allMdx.edges].sort(
    (a, b) => a.node.frontmatter.order - b.node.frontmatter.order
  );
  const _sorted_edges_appended = [..._sorted_edges, ...data.changelog.edges];
  const _content = {
    edges: _sorted_edges_appended
  };

  return <Page content={_content} pkg={idsCSSPackage} />;
};

export default CSSPageTemplate;

export const pageQuery = graphql`
  query ($customPath: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { customPath: { eq: $customPath } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          html
          tableOfContents
          timeToRead
          frontmatter {
            order
            customPath
            componentName
            branch
            category
            subCategory
            title
          }
          fields {
            collection
            slug
          }
        }
      }
    }
    changelog: allMarkdownRemark(filter: { fields: { customPath: { eq: $customPath } } }) {
      edges {
        node {
          id
          fileAbsolutePath
          html
          tableOfContents
          timeToRead
          frontmatter {
            order
            customPath
            componentName
            category
            subCategory
            title
          }
          fields {
            collection
            customPath
            slug
          }
        }
      }
    }
    allMdx(
      filter: { frontmatter: { customPath: { eq: $customPath } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            order
            customPath
            branch
            componentName
            category
            subCategory
            title
          }
          body
        }
      }
    }
  }
`;

CSSPageTemplate.propTypes = {
  data: PropTypes.shape()
};
